import { Button, Dialog, DialogActions, DialogContent, FormControl, MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

const ActionButtonComparecimento = () => {
    // ---- INSTÂNCIAS ----
    // ---- STATES ----
    const [formData, setFormData] = useState({
        nome: '',
        entrada: '',
        horaEntrada: '',
        saida: '',
        horaSaida: '',
        tipoAtendimento: '',
        observacao: ''
    });
    const tiposAtendimento = [
        { nome: 'CONSULTA MÉDICA' },
        { nome: 'REALIZAR EXAMES' },
        { nome: 'ACOMPANHAR O(A) MENOR' },
        { nome: 'ACOMPANHANTE' }
    ];
    const [open, setOpen] = useState(false);

    // ---- FUNÇÕES ----

    const handleChangeInput = (campo, value) => {
        setFormData({ ...formData, [campo]: value });
    };

    const handleNavigate = (params) => {
        setFormData({
            nome: '',
            entrada: '',
            horaEntrada: '',
            saida: '',
            horaSaida: '',
            tipoAtendimento: '',
            observacao: ''
        });
        setOpen(false);
        window.open(
            `/relatorios/comparecimento?nome=${params.nome}&entrada=${params.entrada}&horaEntrada=${params.horaEntrada}&saida=${params.saida}&horaSaida=${params.horaSaida}&atendimento=${params.tipoAtendimento}&observacao=${params.observacao}`
        );
    };

    return (
        <Box>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth={'lg'}>
                <DialogContent>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ textAlign: 'center', padding: '.5em' }}>
                            <Typography variant="h3">DELARAÇÃO DE COMPARECIMENTO</Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ padding: '.4em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Nome"
                                        type="text"
                                        value={formData.nome}
                                        onChange={(e) => handleChangeInput('nome', e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '.5em', padding: '.4em' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
                                    <Box sx={{ display: 'flex', gap: '.7em' }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Entrada"
                                                type="date"
                                                value={formData.entrada}
                                                onChange={(e) => handleChangeInput('entrada', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Hora"
                                                type="time"
                                                value={formData.horaEntrada}
                                                onChange={(e) => handleChangeInput('horaEntrada', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: '.7em' }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Saida"
                                                type="date"
                                                value={formData.saida}
                                                onChange={(e) => handleChangeInput('saida', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Hora"
                                                type="time"
                                                value={formData.horaSaida}
                                                onChange={(e) => handleChangeInput('horaSaida', e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                    </Box>
                                </Box>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Tipo de Atendimento"
                                        select
                                        value={formData.tipoAtendimento}
                                        onChange={(e) => handleChangeInput('tipoAtendimento', e.target.value)}
                                    >
                                        {tiposAtendimento.map((tipo, index) => (
                                            <MenuItem key={index} value={tipo.nome}>
                                                {tipo.nome}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Box>
                            <Box sx={{ padding: '.4em' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Observação"
                                        multiline
                                        minRows={4}
                                        value={formData.observacao}
                                        onChange={(e) => handleChangeInput('observacao', e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="error">Cancelar</Button>
                    <Button variant="contained" onClick={() => handleNavigate(formData)}>
                        Gerar
                    </Button>
                </DialogActions>
            </Dialog>
            <Button onClick={() => setOpen(true)}>Abrir</Button>
        </Box>
    );
};

export default ActionButtonComparecimento;
